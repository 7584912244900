var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],class:[
    'v-select',
    { 'v-select_dropdown-opened': _vm.dropdownIsOpened },
    { 'v-select_selected': _vm.selectedItem } ]},[_c('div',{class:[
      'v-select__selected',
      { 'v-select__selected_has-icon': _vm.getItemIcon(_vm.selectedItem) } ],on:{"click":_vm.toggleDropdown}},[(_vm.getItemIcon(_vm.selectedItem))?_c('VIcon',{staticClass:"v-select__icon",attrs:{"name":_vm.getItemIcon(_vm.selectedItem),"size":_vm.iconSize}}):_vm._e(),_c('transition-group',{staticClass:"v-select__selected-item-texts",attrs:{"name":"texts","tag":"div","mode":"out-in"}},[_c('div',{key:1,staticClass:"v-select__selected-item-placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.selectedItem)?_c('div',{key:2,staticClass:"v-select__selected-item-name"},[_vm._v(" "+_vm._s(_vm.selectedItem.name)+" ")]):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownIsOpened),expression:"dropdownIsOpened"},{name:"bar",rawName:"v-bar"}],staticClass:"v-select__dropdown",style:(_vm.stylesForDropdown)},_vm._l((_vm.dropdownItems),function(dropdownItem,index){return _c('div',{key:index,class:[
        'v-select__dropdown-item',
        { 'v-select__dropdown-item_has-icon': _vm.getItemIcon(dropdownItem) },
        { 'v-select__dropdown-item_selected': _vm.checkOnIsSelectedItem(dropdownItem) }
      ],on:{"click":function($event){return _vm.selectDropdownItem(dropdownItem)}}},[(_vm.getItemIcon(dropdownItem))?_c('VIcon',{staticClass:"v-select__icon",attrs:{"name":_vm.getItemIcon(dropdownItem),"size":_vm.iconSize}}):_vm._e(),_vm._v(" "+_vm._s(dropdownItem.name)+" ")],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }