<template>
  <div
    v-click-outside="closeDropdown"
    :class="[
      'v-select',
      { 'v-select_dropdown-opened': dropdownIsOpened },
      { 'v-select_selected': selectedItem },
    ]"
  >
    <div
      :class="[
        'v-select__selected',
        { 'v-select__selected_has-icon': getItemIcon(selectedItem) },
      ]"
      @click="toggleDropdown"
    >
      <VIcon
        v-if="getItemIcon(selectedItem)"
        class="v-select__icon"
        :name="getItemIcon(selectedItem)"
        :size="iconSize"
      />

      <transition-group
        class="v-select__selected-item-texts"
        name="texts"
        tag="div"
        mode="out-in"
      >
        <div
          :key="1"
          class="v-select__selected-item-placeholder"
        >
          {{ placeholder }}
        </div>

        <div
          v-if="selectedItem"
          :key="2"
          class="v-select__selected-item-name"
        >
          {{ selectedItem.name }}
        </div>
      </transition-group>
    </div>

    <div
      v-show="dropdownIsOpened"
      v-bar
      :style="stylesForDropdown"
      class="v-select__dropdown"
    >
      <div
        v-for="(dropdownItem, index) in dropdownItems"
        :key="index"
        :class="[
          'v-select__dropdown-item',
          { 'v-select__dropdown-item_has-icon': getItemIcon(dropdownItem) },
          { 'v-select__dropdown-item_selected': checkOnIsSelectedItem(dropdownItem) }
        ]"
        @click="selectDropdownItem(dropdownItem)"
      >
        <VIcon
          v-if="getItemIcon(dropdownItem)"
          class="v-select__icon"
          :name="getItemIcon(dropdownItem)"
          :size="iconSize"
        />

        {{ dropdownItem.name }}
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '@/components/ui/VIcon';

export default {
  name: 'VSelect',
  components: {
    VIcon,
  },

  props: {
    iconSize: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    dropdownItems: {
      type: Array,
      required: true,
    },
    dropdownMaxHeight: {
      type: [String, Number],
      default: '100%',
    },
  },
  data: () => ({
    dropdownIsOpened: false,
    selectedItem: null,
  }),
  computed: {
    hasIcon() {
      return this.dropdownItems.some(icon => icon?.iconName);
    },
    stylesForDropdown() {
      return typeof this.dropdownMaxHeight === 'number'
        ? {
          'max-height': `${this.dropdownMaxHeight}px`,
        }
        : this.dropdownMaxHeight;
    },
  },

  methods: {
    toggleDropdown() {
      this.dropdownIsOpened = !this.dropdownIsOpened;
    },
    closeDropdown() {
      this.dropdownIsOpened = false;
    },

    selectDropdownItem(dropdownItem) {
      this.selectedItem = dropdownItem;
      this.closeDropdown();
      this.$emit('on-select-dropdown-item', dropdownItem.value);
    },
    getItemIcon(dropdownItem) {
      return dropdownItem?.iconName;
    },
    checkOnIsSelectedItem(dropdownItem) {
      return dropdownItem.value === this.selectedItem?.value;
    },
  },
}
</script>

<style lang="scss" scoped>
.v-select {
  position: relative;
  width: 100%;
}

.v-select__selected {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  height: 34px;
  background-color: #FFF;
  border: 1px solid #EBEBEB;
  border-radius: 4px;

  .v-select_dropdown-opened & {
    border-color: transparent;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
  }
}

.v-select__selected_has-icon {
  grid-template-columns: max-content 1fr;
  grid-column-gap: 9px;
}

.v-select__selected-item-texts {
  width: 100%;
  text-overflow: ellipsis;
}

.v-select__selected-item-placeholder {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  color: $darkGrey;
  transition: all .2s linear;

  .v-select_selected & {
    top: 3px;
    font-size: 10px;
    line-height: 12px;
    transform: translate(0);
  }
}

.v-select__selected-item-name {
  position: absolute;
  bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  color: #1E1E1E;
}

// сброс глобальных стилей...
.v-select__dropdown {
  min-width: auto;
  border-radius: 0 0 4px 4px;
  box-shadow: none;
}

.v-select__dropdown {
  z-index: 5;
  position: absolute !important;
  top: 100%;
  padding: 4px 0;
  max-height: 224px;
  width: 100%;
  overflow-y: auto !important;
  background-color: #FFF;

  .v-select_dropdown-opened & {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
  }
}

.v-select__dropdown-item {
  display: grid !important;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 0 7px;
  height: 36px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all .1s linear;
  cursor: pointer;

  &:hover {
    background-color: $lightThemeBackground2;
  }
}

.v-select__dropdown-item_has-icon {
  grid-template-columns: max-content 1fr;
  grid-column-gap: 9px;
}

.v-select__dropdown-item_selected {
  background-color: $lightIconBackground;
}

::v-deep.v-select__icon {
  margin-right: 0;

  path {
    fill: #6C78F4;

    &:first-of-type {
      opacity: .3;
    }
  }
}
</style>
